<template>
    <div class="transfers-email">
        <div class="transfers-email__container md:container">
            <div class="transfers-email__wrapper">
                <div v-if="blok.discount_button_image.filename" class="transfers-email__left">
                    <NuxtImg
                        v-if="blok.discount_button_image.filename"
                        :src="blok.discount_button_image?.filename"
                        :title="blok.discount_button_image.title"
                        :alt="blok.discount_button_image.alt"
                        provider="storyblok"
                        format="webp"
                        loading="lazy"
                        @click="reloadWithCode"
                    />
                </div>
                <div class="transfers-email__right">
                    <h5 v-if="blok.title" class="transfers-email__title">
                        {{ blok.title }}
                    </h5>
                    <div class="transfers-email__form">
                        <label v-if="blok.text" for="email-input" class="transfers-email__text">
                            {{ blok.text }}
                        </label>
                        <div class="transfers-email__input-wrapper">
                            <input
                                id="email-input"
                                v-model="email"
                                name="email-input"
                                type="text"
                                placeholder="Email"
                                class="transfers-email__input"
                                required
                            />
                            <div v-if="loading" class="transfers-email__loading">
                                <img
                                    id="imgProgress"
                                    src="~/assets/images/searching.gif"
                                    alt="Loading..."
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div class="transfers-email__details">
                            <div class="transfers-email__disclaimer">
                                {{ blok.disclaimer }}
                            </div>
                            <button class="transfers-email__submit" @click="subscribe">
                                {{ blok.button_label }}
                            </button>
                        </div>
                        <div class="transfers-email__response">
                            <p v-if="error" class="transfers-email__error">
                                {{ blok.email_error_text }}
                            </p>
                            <p v-if="success" class="transfers-email__success">
                                {{ blok.success_message }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IL4TransfersEmailSignup } from '~/types/components/L4TransfersEmailSignup';

const { blok } = defineProps<{ blok: IL4TransfersEmailSignup }>();

const email = ref<string>('');
const loading = ref<boolean>(false);
const error = ref<boolean>(false);
const success = ref<boolean>(false);
const config = useRuntimeConfig();

const { locale, locales } = useI18n();
const localeObj = Object.values(locales.value).find((item: any) => item.code === locale.value);

function reloadWithCode() {
    if (typeof window !== 'undefined') {
        window.location.href = `?code=${blok?.discount_button_code}`;
    }
}

async function subscribe() {
    loading.value = true;
    error.value = false;
    success.value = false;

    if (!checkEmail(email.value)) {
        error.value = true;
        loading.value = false;
        return;
    } else {
        error.value = false;
        loading.value = true;
    }

    const data = {
        sectionName: blok.product_category,
        locationCode: blok.location_code,
        email: email.value,
        listId: config?.public.ITERABLE_LIST_ID,
        culture: localeObj.language,
        signupSource: blok.signupSource ?? '',
    };

    try {
        const response = await fetch(`/api/subscribe`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (result.error) {
            error.value = true;
        } else {
            success.value = true;
            email.value = '';
        }
        loading.value = false;
    } catch (error) {
        loading.value = false;
    }
}

function checkEmail(value: string): boolean {
    const regex =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    return regex.test(value);
}
</script>

<style lang="postcss" scoped>
.transfers-email {
    @apply my-8;
    &__wrapper {
        @apply flex flex-col md:flex-row rounded-md border-bodypurple border-solid border-[1px] text-primary overflow-hidden;
    }
    &__right,
    &__left {
        @apply p-5;
    }
    &__left {
        @apply px-10 flex justify-center items-center;
        img {
            @apply cursor-pointer;
        }
    }
    &__right {
        @apply grow bg-[#f9f9f9];
    }

    &__title {
        @apply font-bold text-xl mb-3;
    }
    &__form {
        @apply flex flex-col;
    }
    &__text {
        @apply mb-2;
    }
    &__input {
        @apply bg-white border-bodypurple border-solid border-[1px] rounded-md text-lg p-1 px-2 text-[#666] leading-4 w-full;
        &-wrapper {
            @apply relative mb-4;
        }
    }
    &__loading {
        @apply bg-white absolute top-0 left-0 w-full h-full opacity-90 z-50 text-center;
        img {
            @apply px-4 m-auto;
        }
    }
    &__details {
        @apply flex flex-col lg:flex-row justify-between;
    }
    &__disclaimer {
        @apply order-2 lg:order-1 italic text-xs text-[#666];
    }
    &__submit {
        @apply order-1 lg:order-2 bg-primary px-4 py-1.5 rounded text-white text-lg mb-3 lg:mb-0;
    }
    &__error {
        @apply text-red-500 text-center mt-2.5;
    }
    &__success {
        @apply bg-lime-500 text-white border-solid border-white border-4 text-center py-2 mt-4;
    }
}
</style>
